/* eslint-disable no-control-regex */

export const Regex = {
    match: {
        email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
        number: /\d/g,
        notNumbers: /\D/g,
        letters: /[a-z]/gi,
        names: /([A-Z]|-|'| )+/gi,
        specialChar: /([^a-z0-9])+/gi,
        currency: /"^\$?\-?([1-9]{1}[0-9]{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\-?\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$"/g
    },
}

//Tests compare validation regex to the input value passing if the expressions are met.
Regex.test = {
    password: [
        {
            regex: /[a-z]/g,
            message: 'Must include a lower case letter'
        },
        {
            regex: /[A-Z]/g,
            message: 'Must include a capital letter'
        },
        {
            regex: /[0-9]/g,
            message: 'Must include a number'
        },
        {
            regex: /[^A-Za-z0-9 ]/g,
            message: 'Must include a special character'
        },
        {
            regex: /.{8}/g,
            message: 'Must be at least 8 characters'
        },
        {
            regex: /^((?!password).)*$/gi,
            message: 'Invalid word not allowed in passwords'
        },
    ],
    creditCard: [
        {
            regex: /\d+/g,
            message: 'Must only include numbers'
        },
        {
            regex: /\d{16}/g,
            message: 'Must be 16 digits'
        },
    ],
    email: [
        {
            regex: Regex.match.email,
            message: 'Please enter a valid email.'
        }
    ],
    currency: [
        {
            regex: /(\d|\.|\$)+/g,
            message: 'Must only include numbers'
        },
        {
            regex: Regex.match.currency,
            message: 'Please enter a valid amount.'
        },
    ],
}

Regex.mask = {
    creditCard: {
        replace: Regex.match.notNumbers,
        format: {
            selector: /\d{4}\B/g,
            replacement: '$&-'
        }
    },
    phoneNumber: {
        replace: Regex.match.notNumbers,
        format: [
            {
                selector: /^(\d{3}\B)/g,
                replacement: '($1) '
            }, {
                selector: / (\d{3}\B)/g,
                replacement: ' $1-'
            }
        ]
    }
}