import React from 'react';
import styled from 'styled-components';
import { QuestRoute } from './api/QuestRoutes';
import { clearAllPersistedState } from './api/usePersistedState';
import Title from './form/Title';
import { HamQuest } from '../../App';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    margin: 1em;
`

const Button = styled.button`
    margin: 1em;
`

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null
        };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error: error
        };
    }

    componentDidCatch(error, errorInfo) {
        HamQuest.post(QuestRoute.errorLogging, {
            error: this.state.error.stack
        })
    }

    render() {
        if (this.state.hasError) {


            return (
                <Wrapper>
                    <Title icon='exclamationTriangle'>An Error has occurred</Title>
                    <div>An Error has occurred resulting in the application crashing. An error report has been sent. We apologize for the inconvenience</div>
                    <Button
                        onClick={async () => {
                            await clearAllPersistedState()
                            window.location.reload()
                        }}
                    >
                        Reload Application
                    </Button>
                </Wrapper>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;