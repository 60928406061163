import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`
const WheelWrapper = styled.div`
    display: block;
    position: relative;
    box-sizing: content-box;
    width: calc(${({ config }) => config.wheelSize}px + 2 * ${({ config }) => config.wheelBorderSize}px);
    height: calc(${({ config }) => config.wheelSize}px + 2 * ${({ config }) => config.wheelBorderSize}px);
    padding: 3px;
    margin: auto;
    background-color: ${({ config }) => config.neutralColor};
    border: solid ${({ config }) => config.wheelColor} 3px;
    border-radius: 50%;
    user-select: none;
    overflow: hidden;
    
    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        height: 0;
        width: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        border: solid transparent 20px;
        border-left-width: 0;
    }
    &::before {
        right: 0px;
        border-right-color: ${({ config }) => config.wheelColor};
    }
    &::after {
        right: -5px;
        border-right-color: ${({ config }) => config.neutralColor};
    }
`
const Wheel = styled.div`
    display: block;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: ${({ config }) => config.wheelSize}px;
    height: ${({ config }) => config.wheelSize}px;
    overflow: hidden;
    border-radius: 50%;
    border: solid ${({ config }) => config.wheelColor} ${({ config }) => config.wheelBorderSize}px;
    background-color: ${({ config }) => config.wheelColor};
    transition: transform ${({ config }) => config.resetDuration};
    transform: rotate(0deg);
    cursor: pointer;

    &::after {
        display: block;
        position: absolute;
        content: '';
        background-color: ${({ config }) => config.neutralColor};
        width: 25px;
        height: 25px;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }

    ${({ config, spinning }) => spinning && `
        transition: transform ${config.spinningDuration} cubic-bezier(0.1, 0.3, 0.1, 1);
        transform: rotate(calc(${config.numberOfTurns} * 360deg + (-360deg * ${config.selectedItem + config.accuracy} / ${config.numberOfItems})));
    `}
`
const Segment = styled.div`
    display: block;
    position: absolute;
    box-sizing: border-box;

    top: 50%;
    left: 50%;
    width: 50%;
    transform-origin: center left;
    transform: translateY(-50%) rotate(calc(${({ index }) => index} * (360deg / ${({ config }) => config.numberOfItems})));

    color: ${({ config }) => config.neutralColor};
    text-align: right;
    padding: 0 25px 0 50px;

    &::before {
        content: ' ';
        display: block;
        position: absolute;
        box-sizing: border-box;
        z-index: -1;
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-left: 0px;
        opacity: 0.25;
    
        --slice-max-width: ${({ config }) => `calc(${config.PI} * ${config.wheelSize}px + ${config.wheelSize}px / 2)`};
        --slice-width: ${({ config }) => `calc((var(--slice-max-width) / ${config.numberOfItems}) - ${config.wheelSliceSpacing}px)`};
        border: solid transparent calc(var(--slice-width) / 2);
        border-left: solid transparent 0;
        border-right: solid ${({ config }) => config.neutralColor} calc(${({ config }) => config.wheelSize}px / 2);
    }
`


const Spinner = ({ items, ...props }) => {
    const [firstSpin, setFirstSpin] = useState(true);
    const [selected, setSelected] = useState(0);
    const [config, setConfig] = useState({
        wheelSize: 300,
        wheelSliceSpacing: 1,
        wheelBorderSize: 5,
        wheelColor: props.primary ?? '#da3768',
        neutralColor: props.secondary ?? 'white',
        PI: Math.PI,
        numberOfItems: items.length,
        selectedItem: selected,
        numberOfTurns: 10,
        // spinningDuration: '10s',
        spinningDuration: '3s',
        resetDuration: '0s',
        accuracy: (360 / items.length) / 2,
    });

    const selectItem = () => {
        setFirstSpin(false)
        if (selected === null) {
            setSelected(Math.floor(Math.random() * items.length));
        } else {
            setSelected(null);
        }
    }

    useEffect(() => {
        if (firstSpin) return;

        if (selected === null) {
            selectItem()
            return;
        }

        const maxWidth = Math.random() * 0.4
        const max = Math.random() > 0.5 ? maxWidth : maxWidth * -1

        setConfig({
            ...config,
            selectedItem: selected,
            accuracy: max,
        })
    }, [selected])

    return (
        <>
            <Wrapper>
                <WheelWrapper config={config}>
                    <Wheel onClick={() => selectItem()} config={config} spinning={selected !== null}>
                        {items.map((item, index) => (
                            <Segment key={index} index={index} config={config}>
                                {item}
                            </Segment>
                        ))}
                    </Wheel>
                </WheelWrapper>
            </Wrapper>
            <div>-{items[selected]}</div>
        </>
    );
}

export default Spinner;
