import Account from "./pages/Account";
import AirPay from "./pages/AirPay";
import Checkout from "./pages/Checkout";
import Bundles from "./pages/Bundles";
import FindUs from "./pages/FindUs";
import Gift from "./pages/Gift";
import Home from "./pages/Home";
import LogIn from "./pages/LogIn";
import Notifications from "./pages/Notifications";
import WashClubs from "./pages/WashClubs";
import Privacy from "./pages/Privacy";
import PromoCodes from "./pages/PromoCodes";
import PurchaseHistory from "./pages/PurchaseHistory";
import Referral from "./pages/Referral";
import RollForWash from "./pages/RollForWash";
import SignUp from "./pages/SignUp";
import Support from "./pages/Support";
import TermsAndConditions from "./pages/TermsAndConditions";
import Wallet from "./pages/Wallet";
import Washes from "./pages/Washes";
import { useGlobalState } from "../_common/globalState/GlobalStateProvider";
import GiftCards from "./pages/GiftCards";

const MenuRoutes = () => {
    const {
        state: {
            active_user,
            site_config
        },
    } = useGlobalState();

    const routes = {
        wallet: {
            route: '/wallet',
            label: 'Wallet',
            order: 1,
            menuItem: false,
            element: <Wallet />
        },
        washes: {
            route: '/washes',
            label: 'Washes',
            order: 2,
            menuItem: true,
            isGuestUser: true,
            element: <Washes />,
            icon: 'drop'
        },
        washClub: {
            route: '/washClub',
            label: 'Wash Clubs',
            order: 3,
            menuItem: true,
            isGuestUser: true,
            element: <WashClubs />,
            icon: 'calendar',
        },
        airPAY: {
            route: '/airPay',
            label: 'AirPAY',
            order: 4,
            menuItem: true,
            element: <AirPay />,
            icon: 'mobile',
        },
        referral: {
            route: '/referral',
            label: 'Invite Friends',
            order: 5,
            menuItem: true,
            element: <Referral />,
            icon: 'group',
        },
        deals: {
            route: '/bundles',
            label: 'Bundles',
            order: 3.5,
            menuItem: true,
            isGuestUser: true,
            element: <Bundles />,
            icon: 'tag',
        },
        promoCode: {
            route: '/promocode',
            label: 'Promo Codes',
            order: 6.5,
            menuItem: true,
            element: <PromoCodes />,
            icon: 'barcode',
        },
        giftCards: {
            route: '/giftCards',
            label: 'Gift Cards',
            order: 6.6,
            menuItem: true,
            isGuestUser: true,
            element: <GiftCards />,
            icon: 'creditCard',
        },
        // gift: {
        //     route: '/gift',
        //     label: 'Gift a Wash',
        //     order: 7,
        //     menuItem: false,
        //     element: <Gift />,
        //     icon: 'gift',
        // },
        findUs: {
            route: '/findUs',
            label: 'Find Us',
            order: 8,
            menuItem: true,
            isGuestUser: true,
            element: <FindUs />,
            icon: 'map',
        },
        myAccount: {
            route: '/account',
            label: 'Account',
            order: 9,
            menuItem: false,
            element: <Account />,
            icon: 'user',
        },
        notifications: {
            route: '/notifications',
            label: 'Notifications',
            order: 10,
            menuItem: true,
            element: <Notifications />,
            icon: 'bell',
        },
        support: {
            route: '/Support',
            label: 'Support',
            order: 11,
            menuItem: false,
            isGuestUser: true,
            element: <Support />,
        },
        privacyPolicy: {
            route: '/privacy',
            label: 'Privacy Policy',
            order: 12,
            menuItem: false,
            isGuestUser: true,
            element: <Privacy />
        },
        termsAndConditions: {
            route: '/termsAndConditions',
            label: 'Terms and Conditions',
            order: 13,
            menuItem: false,
            isGuestUser: true,
            element: <TermsAndConditions />
        },
        home: {
            route: '/',
            label: 'Home',
            order: 14,
            menuItem: false,
            isGuestUser: true,
            element: <Home />
        },
        logIn: {
            route: '/logIn',
            label: 'Log In',
            order: 15,
            menuItem: false,
            isGuestUser: true,
            element: <LogIn />
        },
        signUp: {
            route: '/signUp',
            label: 'Sign Up',
            order: 16,
            menuItem: false,
            isGuestUser: true,
            element: <SignUp />
        },
        checkout: {
            route: '/checkout',
            label: 'Cart',
            order: 17,
            menuItem: false,
            element: <Checkout />
        },
        purchaseHistory: {
            route: '/purchaseHistory',
            label: 'Purchase History',
            order: 18,
            menuItem: false,
            element: <PurchaseHistory />
        },
        // rollForWash: {
        //     route: '/rollForWash',
        //     label: 'Roll For Wash',
        //     order: 19,
        //     menuItem: false,
        //     element: <RollForWash />
        // },
    }

    if (site_config.disabledRoutes) {
        site_config.disabledRoutes.forEach(e => routes[e].disabled = true)
    }

    return routes;
}

export default MenuRoutes;