import { useState, useEffect } from 'react';
import { generateId } from './utility';
import { QUEST_KEYS } from '../globalState/GlobalStateProvider';

export function usePersistedState(key, initialState) {
    const storageKey = `hamilton.${key}`;
    const hydrateState = () => {
        try {
            const persisted = window.localStorage.getItem(storageKey);
            const parsedRes = JSON.parse(persisted)

            if (parsedRes.version !== initialState.version) {
                console.warn('State version mismatch, returning initial state...')
                caches
                    .keys()
                    .then((names) => names.forEach(e => caches.delete(e)));
                window.location.reload(true);

                return initialState;
            }
            return parsedRes || initialState;
        } catch {
            console.warn('Failed to read current state version, returning initial state...')
            return initialState;
        }
    };

    const [state, setState] = useState(hydrateState);

    useEffect(() => {
        window.localStorage.setItem(storageKey, JSON.stringify(state));
    }, [state, storageKey]);

    return [state, setState];
}

export const DEVICE_ID_KEYS = 'HSDeviceIdKey';

export async function UseDeviceId() {
    const storageKey = `deviceId`;

    const getLocalId = () => {
        const persisted = window.localStorage.getItem(storageKey);
        if (persisted) {
            const parsedRes = JSON.parse(persisted);
            setCacheDeviceID(parsedRes)
            return parsedRes;
        }
        return generateDeviceId();
    }

    const getCacheDeviceID = async () => {
        if (!('caches' in window)) {
            console.error('Cache API not supported');
            return getLocalId();
        }

        try {
            const cache = await caches.open(DEVICE_ID_KEYS);
            const response = await cache.match('/' + DEVICE_ID_KEYS);
            if (!response) {
                console.warn('No Device ID found, trying local.');
                return getLocalId();
            }
            const tokens = await response.json();
            console.log('Retrieved Device ID from cache successfully');
            return tokens.deviceId;
        } catch (error) {
            console.error('Failed to retrieve the Device ID:', error);
        }
    }

    const setCacheDeviceID = async (deviceId) => {
        try {
            if (!('caches' in window)) {
                throw new Error('Cache API not supported');
            }
            const cache = await caches.open(DEVICE_ID_KEYS);
            const payload = JSON.stringify({ deviceId });
            const response = new Response(payload, { headers: { 'Content-Type': 'application/json' } });
            await cache.put('/' + DEVICE_ID_KEYS, response);
            console.log("Cached Device ID successful");
        } catch (error) {
            console.error('Failed to store the Device ID:', error);
        }
    }

    const generateDeviceId = () => {
        const Id = generateId(20);
        window.localStorage.setItem(storageKey, JSON.stringify(Id));
        setCacheDeviceID(Id)
        return Id;
    }

    try {
        const persisted = await getCacheDeviceID();
        console.log('Device ID:', persisted)
        if (!persisted) {
            console.warn('No Device ID set, generating...')
            return generateDeviceId();
        }
        return persisted;
    } catch {
        console.warn('No Device ID set, generating...')
        return generateDeviceId();
    }
}

export async function clearAllPersistedState() {
    const cache = await caches.open(QUEST_KEYS);
    const success = await cache.delete('/' + QUEST_KEYS);
    if (success) {
        console.log("Cache deletion successful");
    } else {
        console.log("Cache entry not found");
    }

    Object.keys(window.localStorage).forEach(key => {
        if (key.startsWith('hamilton.')) {
            window.localStorage.removeItem(key);
        }
    });
}