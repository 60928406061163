import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, MobileStyle } from './components/_common/globalState/GlobalStyles';
import { getSubdomain, isMobile, lastKeyPress } from './components/_common/api/utility';
import { QUEST_KEYS, useGlobalState } from './components/_common/globalState/GlobalStateProvider';
import EcomRouter from './components/_common/navigation/EcomRouter';
import Modal from './components/_common/Modal';
import MobileRouter from './components/_common/navigation/MobileRouter';
import LoadingScreen from './components/_common/LoadingScreen';
import ErrorBoundary from './components/_common/ErrorBoundary';
import './App.css';
import Notification from './components/_pwa/Notification';
import { HamQuestGiver } from './components/_common/api/HamQuest';
import QRModal from './components/_common/QRModal';
import AccountUpdate from './components/_common/AccountUpdate';
import { defaultSiteConfig } from './InitialState';

// export const HamQuest = CLog.bindClass(new HamQuestGiver());
// CLog.bindToClassMethods(HamQuest, HamQuest.fetchQuest, HamQuest.setLifecycle);
export const HamQuest = new HamQuestGiver();

const App = () => {
    const {
        state: {
            subdomain,
            app_key,
            dark_theme,
            site_config,
            isLoading,
        },
        getSiteKey,
        updateAccessToken,
        updateUnauthorized,
        getSiteConfig,
    } = useGlobalState();

    const [error, setError] = useState('')

    const currentSub = getSubdomain();
    HamQuest.addApiUrl(process.env.REACT_APP_API_URL);
    HamQuest.addLocalKey(QUEST_KEYS);

    if (app_key && currentSub === subdomain) {
        HamQuest.replaceHeaderKey({ app_key });
    }

    useLayoutEffect(() => {
        console.log('ENV:', process.env.REACT_APP_ENV);
        updateUnauthorized();
        if (app_key) getSiteConfig();
        if (isMobile(navigator.userAgent)) HamQuest.replaceHeaderKey({ isMobile: true });

        const keyDownHandler = e => lastKeyPress.key = e.key;
        document.addEventListener('keydown', keyDownHandler);
        return () => document.removeEventListener('keydown', keyDownHandler);
    }, []);

    useEffect(() => {
        if (currentSub === 'testing') return;

        if (subdomain !== currentSub || !app_key) {
            getSiteKey(subdomain)
                .catch((ex) => setError(ex))
        }
    }, [subdomain]);

    return (
        <ErrorBoundary>
            <Notification>
                {currentSub === 'testing' ?
                    <ThemeProvider theme={defaultSiteConfig.themes.light}>
                        <Modal />
                        <AccountUpdate />
                    </ThemeProvider> :
                    <LoadingScreen message={error} />
                }
                {!isLoading && (
                    <ThemeProvider theme={dark_theme ? site_config.themes.dark : site_config.themes.light}>
                        <Modal />
                        <QRModal />
                        {isMobile(navigator.userAgent) ? <MobileStyle fontSize={site_config.fontSize || 10} /> : <GlobalStyle />}
                        {site_config.has_app && isMobile(navigator.userAgent)
                            ? <MobileRouter />
                            : ((app_key && subdomain === currentSub) && <EcomRouter />) || <span>Please Open on a mobile device.</span>}
                    </ThemeProvider>
                )}
            </Notification>
        </ErrorBoundary>
    );
}

export default App;