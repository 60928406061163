import React, { useState, useEffect } from 'react'
// import toast, { Toaster } from 'react-hot-toast';
import { onMessageListener } from '../../firebase';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalState } from '../_common/globalState/GlobalStateProvider';

export const Notif = React.createContext();

const Toaster = styled(ToastContainer)`
    font-size: 0.5em;
`

const Notification = ({ children }) => {
    const {
        state: {
            site_config
        },
    } = useGlobalState();

    const [notification, setNotification] = useState({ body: '', config: {} });

    const notify = () => ToastDisplay()

    const ToastDisplay = () => {

        switch (notification.type) {
            case 'success':
                return toast.success(
                    notification.body,
                    {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: site_config.dark_theme ? 'dark' : 'light',
                    }
                );

            default:
                toast.info(
                    notification.body,
                    {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: site_config.dark_theme ? 'dark' : 'light',
                    }
                );
        }
    };

    useEffect(() => {
        if (notification.title || notification.body) {
            notify()
        }
    }, [notification])

    // requestForToken();

    onMessageListener()
        .then((payload) => {
            setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
        })
        .catch((err) => console.log('failed: ', err));

    const contextValue = {
        setNotification
    }

    return (
        <Notif.Provider value={contextValue}>
            <Toaster />
            {children}
        </Notif.Provider>

    )
}

export default Notification