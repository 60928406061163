import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
    body {
        background: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.text};
        font-size: calc(10px + 2vmin);
    }
    ::selection {
        background-color: ${({ theme }) => theme.selection};
        // color: black;
    }
`
export const MobileStyle = createGlobalStyle`
    body {
        background: ${({ theme }) => theme.mobile.body};
        color: ${({ theme }) => theme.mobile.text};
        font-size: calc(${({ fontSize }) => fontSize}px + 2vmin);

        margin: 0;
        font-family: Satoshi;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow: hidden !important;
    }
    html {
        /* height: calc(var(--100vvh, 100vh)); */
        /* width: calc(var(--100vvw, 100vw)); */
        -webkit-overflow-scrolling: touch;
        backface-visibility: hidden;
        overflow: hidden !important;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
`