
import React, { useState } from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../_common/globalState/GlobalStateProvider';
import { generateId } from '../../_common/api/utility';
import Input from '../../_common/form/Input';

const Col = styled.div`
    display: flex;
    flex-direction: column;
`
const Row = styled.div`
    display: flex;
`

const MenuUpdater = ({ item, ...props }) => {
    const {
        state: {
            site_config,
        },
        updateSiteConfig
    } = useGlobalState();
    const [menuItem, setItems] = useState(item);

    return (
        <Col>
            <span>{menuItem.tag.replace(/_/g, '')}</span>
            <Row>
                <Input
                    standalone
                    label={'Menu Label'}
                    value={menuItem.label}
                    onChange={f => {
                        const newTheme = { ...menuItem };
                        newTheme.label = f.target.value
                        setItems(newTheme)
                    }}
                    onBlur={() => {
                        const newTheme = { ...site_config };
                        const index = newTheme.menuItems.findIndex(e => e.tag === menuItem.tag)
                        newTheme.menuItems[index] = menuItem;
                        updateSiteConfig(newTheme)
                    }}
                />
                <Input
                    standalone
                    label={'URL'}
                    value={menuItem.href}
                    onChange={f => {
                        const newTheme = { ...menuItem };
                        if (f.target.value.charAt(0) !== '/') {
                            newTheme.href = '/' + f.target.value
                        } else {
                            newTheme.href = f.target.value
                        }
                        setItems(newTheme)
                    }}
                    onBlur={() => {
                        if (menuItem.href.charAt(0) !== '/') return
                        const newTheme = { ...site_config };
                        const index = newTheme.menuItems.findIndex(e => e.tag === menuItem.tag)
                        newTheme.menuItems[index] = menuItem;
                        updateSiteConfig(newTheme)
                    }}
                />
            </Row>
        </Col>
    )
}

const MenuRouteUpdater = ({ ...props }) => {
    const {
        state: {
            site_config,
        },
    } = useGlobalState();

    return (
        <Col>
            <span>- Menu Items</span>
            {site_config.menuItems.map(e => <MenuUpdater item={e} key={generateId()} />)}
        </Col>
    )
}

export default MenuRouteUpdater;

