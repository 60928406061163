import React from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    ${({ align }) => align ? 'align-items: ' + align + ';' : null}
    ${({ justify }) => justify ? 'justify-content: ' + justify + ';' : null}
`

const Col = ({ children, ...props }) => {
    return (
        <Wrapper {...props}>
            {children}
        </Wrapper>
    );
}

export default Col;
