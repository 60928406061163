
import React, { useState } from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../_common/globalState/GlobalStateProvider';
import { generateId } from '../../_common/api/utility';
import Input from '../../_common/form/Input';

const Col = styled.div`
    display: flex;
    flex-direction: column;
`
const Row = styled.div`
    display: flex;
`

const MenuUpdater = ({ item, ...props }) => {
    const {
        state: {
            site_config,
        },
        updateSiteConfig
    } = useGlobalState();
    const [menuItem, setItems] = useState(item);

    return (
        <Col>
            <span>{menuItem.tag.replace(/_/g, '')}</span>
            <Row>
                <Input
                    standalone
                    label={'Menu Label'}
                    value={menuItem.label}
                    onChange={f => {
                        const newShopItem = { ...menuItem };
                        newShopItem.label = f.target.value
                        setItems(newShopItem)
                    }}
                    onBlur={() => {
                        const newShopItem = { ...site_config };
                        const index = newShopItem.shopItems.findIndex(e => e.tag === menuItem.tag)
                        newShopItem.shopItems[index] = menuItem;
                        updateSiteConfig(newShopItem)
                    }}
                />
                <Input
                    standalone
                    label={'URL'}
                    value={'/' + menuItem.href}
                    onChange={f => {
                        const newTheme = { ...menuItem };
                        newTheme.href = f.target.value.replace(/\//g, '')
                        setItems(newTheme)
                    }}
                    onBlur={() => {
                        if (menuItem.href.charAt(0) === '/') return
                        const newTheme = { ...site_config };
                        const index = newTheme.shopItems.findIndex(e => e.tag === menuItem.tag)
                        newTheme.shopItems[index] = menuItem;
                        updateSiteConfig(newTheme)
                    }}
                />
            </Row>
        </Col>
    )
}

const ShopItemsUpdater = ({ ...props }) => {
    const {
        state: {
            site_config,
        },
    } = useGlobalState();

    return (
        <Col>
            <span>- Shop Items</span>
            {site_config.shopItems.map(e => <MenuUpdater item={e} key={generateId()} />)}
        </Col>
    )
}

export default ShopItemsUpdater;

