export const QuestRoute = {
    paymentForm: {
        setup: '/payments/hosted-forms',
        exact: '/payments/exact/form_data',
        worldpay: '/payments/worldpay/addnew',
        moneris: '/payments/moneris/setup',
    },
    validate: {
        email: '/users/unique',
    },
    purchase: {
        finalize: '/orders',
        resendOrderEmail: '/users/purchases/resend'
    },
    user: {
        login: '/users/login',
        refreshToken: '/users/tokens',
        info: '/users',
        loyaltyPoints: '/users/points',
        notifications: '/users/notifications',
        unreadNotifications: '/users/notifications?status=unread',
        purchaseHistory: '/users/purchases',
        wallet: '/users/wallet',
        signUp: '/users',
        washClub: '/users/washclub',
        vehicle: '/users/vehicle',
        confirmationEmail: '/users/emails/confirmation',
        confirmedEmail: '/users/emails/confirmed',
        forgotPassword: '/users/emails/forgot-password',
        recoverPassword: '/users/forgot-password',
        updatePassword: '/users/update-password',
        firebaseToken: '/users/firebase',
        deals: '/users/deals',
        delete: '/users',
        locationAssignment: '/users/homeLocation',
    },
    merchant: {
        processor: '/settings/app/processor/mobile',
        settings: '/user/Settings',
        siteKey: '/accounts/site-key',
        siteConfig: '/accounts/site-config',
        privacyPolicy: '/accounts/settings/app?name=PrivacyPolicy',
        support: '/accounts/settings/app',
        terms: '/accounts/settings/app?name=Terms',
        WCTerms: '/loyalty/settings?name=Terms&category=AppWashClub',
        referralPoints: '/loyalty/settings?name=ReferrerPoints&category=AppReferral',
        states: '/accounts/states',
        subdomain: '/accounts/subdomain',
        p2p: '/settings/p2p',
    },
    giftCard: {
        balance: '/gift-cards',
        recharge: '/gift-cards',
    },
    products: {
        washes: '/products?type=AppWash',
        washClubs: '/products?type=AppClub',
        bundles: '/products?type=AppBundle',
        giftCards: '/products?type=giftCard&type=giftCardRecharge',
        merchandise: '/products?type=merchandise',
        promoCode: '/promo-codes',
        deals: '/deals'
    },
    locations: '/locations',
    device: {
        clearId: '/devices'
    },
    tempToken: '/temptoken',
    appVersion: '/system/app-version',
    siteConfig: '/system/site-config',
    buildVersions: '/system/build-versions',
    errorLogging: '/users/errors',
    firebaseUpload: '/accounts/settings/firebase',
    imgUpload: '/accounts/upload',
    downloadWrapper: (OS, name) => `/packages/${OS}?name=${name}`,
    targetedNotification: `/notifications/target`,
}