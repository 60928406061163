import {
    faPencilAlt,
    faTachometerAlt,
    faClipboardList,
    faMobileAlt,
    faIdCard,
    faQrcode,
    faCog,
    faCommentAlt,
    faHome,
    faArrowAltCircleDown,
    faChevronDown,
    faUser,
    faBell,
    faSearch,
    faSearchMinus,
    faFilter,
    faTrashAlt,
    faTimes,
    faCheckCircle,
    faSpinner,
    faDownload,
    faSave,
    faBan,
    faInfoCircle,
    faHourglass,
    faExclamationCircle,
    faExclamationTriangle,
    faShoppingCart,
    faCircle,
    faDroplet,
    faCalendarAlt,
    faUserGroup,
    faTag,
    faMobileScreenButton,
    faWallet,
    faCreditCard,
    faEye,
    faEyeSlash,
    faHeart,
    faSun,
    faMoon,
    faShoppingBasket,
    faMap,
    faDirections,
    faCircleChevronLeft,
    faPowerOff,
    faBars,
    faX,
    faGift,
    faCarSide,
    faBarcode,
    faPlus,
    faMinus,
    faHistory,
    faEnvelope,
    faPhone,
    faCar,
    faList,
    faMapPin,
    faArrowUpFromBracket,
    faSquarePlus,
    faDollarSign,
    faAward,
    faArrowRotateRight,
    faLock,
    faShippingFast,
    faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';

export const IconIndex = {
    undo: faRotateLeft,
    shipping: faShippingFast,
    pencil: faPencilAlt,
    tachometer: faTachometerAlt,
    clipboard: faClipboardList,
    mobileAlt: faMobileAlt,
    idCard: faIdCard,
    QRcode: faQrcode,
    cog: faCog,
    comment: faCommentAlt,
    home: faHome,
    arrowCircleDown: faArrowAltCircleDown,
    chevronDown: faChevronDown,
    user: faUser,
    bell: faBell,
    search: faSearch,
    searchMinus: faSearchMinus,
    filter: faFilter,
    trash: faTrashAlt,
    times: faTimes,
    checkCircle: faCheckCircle,
    spinner: faSpinner,
    download: faDownload,
    save: faSave,
    ban: faBan,
    infoCircle: faInfoCircle,
    hourglass: faHourglass,
    exclamationCircle: faExclamationCircle,
    exclamationTriangle: faExclamationTriangle,
    cart: faShoppingCart,
    circle: faCircle,
    drop: faDroplet,
    calendar: faCalendarAlt,
    group: faUserGroup,
    tag: faTag,
    mobile: faMobileScreenButton,
    wallet: faWallet,
    creditCard: faCreditCard,
    eye: faEye,
    crossEye: faEyeSlash,
    heart: faHeart,
    sun: faSun,
    moon: faMoon,
    basket: faShoppingBasket,
    map: faMap,
    directions: faDirections,
    circleChevronLeft: faCircleChevronLeft,
    powerOff: faPowerOff,
    bars: faBars,
    cross: faX,
    gift: faGift,
    sideCar: faCarSide,
    barcode: faBarcode,
    plus: faPlus,
    minus: faMinus,
    history: faHistory,
    envelope: faEnvelope,
    phone: faPhone,
    car: faCar,
    list: faList,
    pin: faMapPin,
    share: faArrowUpFromBracket,
    squarePlus: faSquarePlus,
    dollarSign: faDollarSign,
    award: faAward,
    reload: faArrowRotateRight,
    lock: faLock,
};