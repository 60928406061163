import { deleteApp, initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { QuestRoute } from "./components/_common/api/QuestRoutes";
import { HamQuest } from "./App";
import { getFirebaseConfig } from "./InitialState";
import { getSubdomain } from "./components/_common/api/utility";

const sub = getSubdomain();
if (sub) {
    fetch(process.env.REACT_APP_API_URL + '/firebase?subdomain=' + sub)
        .then(res => res.json())
        .then(res => {
            res = res.resultData;
            deleteApp(app)
                .then(() => {
                    if (!res) return;
                    console.log('Using:', res)
                    vapidKey = res.vapidKey
                    firebaseConfig = res.firebaseConfig
                    app = initializeApp(res.firebaseConfig);
                    messaging = getMessaging(app);
                    console.log('App Reinit Successful');
                })
                .catch(error => {
                    console.error('Error reinitializing Firebase app:', error);
                });
        })
}

let firebaseConfig = getFirebaseConfig(process.env.REACT_APP_ENV);
let app = initializeApp(firebaseConfig);
let messaging = getMessaging(app);
let vapidKey = process.env.REACT_APP_VAPID_KEY

export const requestForToken = () => {
    console.log(firebaseConfig, messaging, vapidKey);
    getToken(messaging, { vapidKey })
        .then((currentToken) => {
            if (currentToken) {
                HamQuest.post(QuestRoute.user.firebaseToken, {
                    token: currentToken
                });
                console.log('Token added successfully.');
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        })
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });