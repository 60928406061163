
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../_common/form/Icon';
import { hexComplementary } from '../../_common/api/utility';
import { Notif } from '../../_pwa/Notification';

const Col = styled.div`
    display: flex;
    flex-direction: column;
`
const Row = styled.div`
    display: flex;
    align-items: center
`

const Wrapper = styled.div`
    padding: 0.2em;
    display: flex;
    align-items: center;
    transition: all 0.3s;
`
const ColorPickerInput = styled.input`
    margin: 0 1em;
    cursor: pointer;
`
const Demo = styled.div`
    width: 2em;
    height: 1em;
    margin: 0 0.5em;
    background-color: ${({ color }) => color};
    border-radius: 0.5rem;
    border: 1px solid #adadad;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Label = styled.div`
    width: 10em;
`

const ColorPicker = ({ objectKey, val, originalVal, updateFunc, colouredTheme, ...props }) => {
    const { setNotification } = useContext(Notif);

    const [colorValue, setColorValue] = useState(typeof val === 'string' ? val.substring(0, 7) : null);
    const [opacity, setOpacity] = useState(typeof val === 'string' ? val.substring(7, 9) : null);
    if (typeof val === 'object') return null;
    const label = objectKey.split('.');

    const callbackUpdate = () => {
        updateFunc(objectKey, colorValue + (opacity !== 'ff' && opacity !== '00' ? opacity : ''), colouredTheme)

        if (props.toast) {
            setNotification({
                ...props.toast,
                body: `${props.toast.body} "${props.label || label[label.length - 1]}"`,
            })
        }
    }

    return (
        <Wrapper>
            <Label><Icon icon={props.light ? 'sun' : 'moon'} /> {props.label || label[label.length - 1]}</Label>
            <Row>
                <ColorPickerInput
                    type='color'
                    value={colorValue}
                    onChange={(e) => setColorValue(e.target.value)}
                    onBlur={callbackUpdate}
                />
                <ColorPickerInput
                    value={colorValue}
                    onChange={(e) => setColorValue(e.target.value)}
                    onBlur={callbackUpdate}
                />
            </Row>

            <input
                type='range'
                min={0}
                max={255}
                value={parseInt(opacity ? opacity : 'ff', 16)}
                onInput={(e) => setOpacity((parseInt(e.target.value)).toString(16))}
                onMouseUp={callbackUpdate}
            />
            <Demo color={colorValue + opacity} />
            {colorValue + opacity !== originalVal &&
                <Row>
                    <Demo
                        color={originalVal}
                        title='Click to revert to Original'
                        style={{ cursor: 'pointer' }}
                        onClick={() => updateFunc(objectKey, originalVal)}
                    >
                        <Icon icon='undo' style={{ fontSize: '0.5em', color: hexComplementary(originalVal || '#000000') }} />
                    </Demo>
                </Row>

            }
        </Wrapper>
    )
}

export default ColorPicker;
